<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-02-17 16:21:33
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-11-27 17:36:31
-->

<template>
  <el-card class="box" v-loading="loading">
    <div class="avatar">
      <el-avatar :src="model.avatar_pic"></el-avatar>
    </div>
    <el-descriptions title="基本信息" border>
      <el-descriptions-item label="姓名">{{ model.name }}</el-descriptions-item>
      <el-descriptions-item label="手机">{{
        model.mobilePhone
      }}</el-descriptions-item>
      <el-descriptions-item label="账号">{{
        model.account
      }}</el-descriptions-item>

      <el-descriptions-item label="角色">
        {{ model.roles ? model.roles.map((o) => o.name).join("、") : "" }}
      </el-descriptions-item>
      <el-descriptions-item label="状态">
        <el-tag type="success" v-if="model.enabled">正常</el-tag>
        <el-tag type="danger" v-if="!model.enabled">停用</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="创建时间">{{
        model.create_at
      }}</el-descriptions-item>
    </el-descriptions>
  </el-card>
  <el-card class="box">
    <el-descriptions title="安全设置" :column="2">
      <el-descriptions-item label="密码">
        <el-button type="primary" link @click="visiblePasswordDialog = true"
          >修改</el-button
        >
      </el-descriptions-item>
    </el-descriptions>
  </el-card>

  <el-dialog
    title="修改密码"
    v-model="visiblePasswordDialog"
    width="600px"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="手机">
        {{ model.mobilePhone }}
        <el-button
          type="primary"
          size="small"
          link
          ref="vaptcha"
          style="margin-left: 20px"
          @click="onGetSmsCode"
          :disabled="btnSms.disabled"
          >{{ btnSms.text }}</el-button
        >
      </el-form-item>

      <el-form-item label="验证码" prop="SmsCode">
        <el-col :span="10" :offset="0">
          <el-input
            v-model="form.SmsCode"
            placeholder="请输入短信验证码"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="密码" prop="Password">
        <el-col :span="16" :offset="0">
          <el-input
            v-model="form.Password"
            placeholder="请输入登录密码"
            @input="checkPasswordStrong"
          ></el-input>
        </el-col>
        <el-col :span="4" :offset="1">
          <el-link type="primary" :underline="false" @click="onRandomPassword"
            >随机</el-link
          >
        </el-col>
        <div style="width: 60%">
          <el-progress
            v-if="form.Password"
            :percentage="strengthScore"
            style="margin-top: 5px"
            :status="strengthStatus"
          >
            <span>{{ strengthText }}</span>
          </el-progress>
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" :loading="saving" @click="onSubmitPwd"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import md5 from "js-md5";

export default {
  data() {
    return {
      loading: false,
      saving: false,
      visiblePasswordDialog: false,
      model: {},
      rules: {
        SmsCode: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
        Password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
          {
            min: 6,
            message: "密码长度不能小于6位",
            trigger: "blur",
          },
        ],
      },
      form: {},
      btnSms: {
        text: "获取验证码",
        disabled: false,
      },
      strengthScore: 0,
      strengthText: "弱", //密码强度颜色
      strengthStatus: "",
    };
  },

  created() {
    this.loadData();

    var _this = this;

    this.$http.get("v1/common/config").then((res) => {
      if (res.code === 0) {
        this.config = res.data;
        window
          .vaptcha({
            vid: res.data.vaptchaVid,
            mode: "invisible",
          })
          .then(function (VAPTCHAObj) {
            _this.vaptcha = VAPTCHAObj;
            // 渲染验证组件
            VAPTCHAObj.render();

            // 验证成功进行后续操作
            VAPTCHAObj.listen("pass", function () {
              _this.btnSms.disabled = true;
              var serverToken = VAPTCHAObj.getServerToken();
              _this.$http
                .post("v1/common/sms/captcha", {
                  mobile: _this.model.mobilePhone,
                  type: 4,
                  token: serverToken.token,
                  server: serverToken.server,
                  scene: 0,
                })
                .then((res) => {
                  if (res.code == 0) {
                    _this.$message({
                      type: "success",
                      message: res.msg,
                    });
                    //发送成功，倒计时
                    _this.btnSms.disabled = true;
                    var sec = 60;
                    var t = setInterval(() => {
                      sec--;
                      if (sec <= 0) {
                        clearInterval(t);
                        _this.btnSms.disabled = false;
                        _this.btnSms.text = "获取验证码";
                        VAPTCHAObj.reset();
                      } else {
                        _this.btnSms.text = `${sec}s`;
                      }
                    }, 1000);
                  } else {
                    VAPTCHAObj.reset();
                  }
                });
            });

            VAPTCHAObj.listen("close", function () {
              console.log("验证弹窗关闭触发");
              // 验证弹窗关闭触发
              VAPTCHAObj.reset();
            });
          });
      }
    });
  },

  methods: {
    /**
     * 加载个人数据
     */
    loadData() {
      this.loading = true;
      this.$http
        .get("/admin/v1/center")
        .then((res) => {
          if (res.code == 0) {
            //
            this.model = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * 获取验证码
     */
    onGetSmsCode() {
      this.vaptcha.validate();
    },

    onRandomPassword() {
      this.$http.get("v1/common/password/create?len=8").then((res) => {
        if (res.code === 0 && res.data) {
          this.form.Password = res.data;
          this.checkPasswordStrong();
        }
      });
    },

    /**
     * 检查密码强度
     */
    checkPasswordStrong() {
      this.$http
        .post("v1/common/password/checkstrong", {
          password: this.form.Password,
        })
        .then((res) => {
          if (res.code === 0 && res.data) {
            if (res.data >= 80) {
              this.strengthScore = 100;
              this.strengthStatus = "success";
              this.strengthText = "强";
            } else if (res.data >= 50) {
              this.strengthScore = 66;
              this.strengthStatus = "warning";
              this.strengthText = "中";
            } else {
              this.strengthScore = 33;
              this.strengthStatus = "exception";
              this.strengthText = "弱";
            }
          }
        });
    },

    /**
     * 提交密码修改
     */
    onSubmitPwd() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          var data = Object.assign({}, this.form);
          data.Password = md5(this.form.Password + this.model.salt);
          this.$http
            .post("/admin/v1/center/changePwd", data)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visiblePasswordDialog = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
              }
            })
            .finally(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
